import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';

//let store = "";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <StrictMode>
            <App />
      </StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
