import React from 'react';
//import {  Button,Modal,Spinner} from 'reactstrap';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import PropTypes from 'prop-types';

export default function ModalSpinner(props) {

  const modal = props.isloading;

  const backdrop = 'static';


  return (
    <div>
      <Modal
        isOpen={modal}
        backdrop={backdrop}
        fade={false}
        className="position-absolute top-50 start-50 translate-middle"
      >
        <Button
            color="primary"
            disabled        
        >
            <Spinner 
                size="sm"
                >
            </Spinner>
            <span>
                {' '}Loading
            </span>
        </Button>
      </Modal>
    </div>
  );
}

ModalSpinner.propTypes = {
  className: PropTypes.string,
};
