import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import ExNavbar from '../components/Navbar';
import TerminList from '../components/TerminList';
import SparepartList from '../components/sparepart/SparepartList';
import TerminWorkSearch from '../components/TerminWorkSearch';
import TerminWorkorder from '../components/TerminWorkorder';
import TerminWorkList from '../components/TerminWorkList';
import ClientSetup from '../components/ClientSetup';
import TerminSearch from './TerminSearch';
import DatesList from '../components/dateslist/DatesList';
import ServiceConfig from '../components/configuration/ServiceConfig';

import { ApiCall } from '../functions/apicall';

import CalendarDay from '../components/calendar/CalendarDay';
import CalendarWeek from '../components/calendar/CalendarWeek';
import CalendarMonth from '../components/calendar/CalendarMonth'


export default class Home extends Component {

    state = {
        isLoading: true,
        time: Date.now(),
        isFetchData: false,
        myService: null,
        myUser: null,
        fetchDate: null,
        calendarId: null,
        pCode: '',
        token: null,
        comp: 'TerminList',
        searchString: null,
        isFail: false,
        devApp: false,
        clientId: '',
        clientIdIsFail: false,
        clientType: '',
        clientTypeIsFail: false,
        uuidFail: false
    }


    constructor(props) {
        super(props);

        
        this.myService = null;
        this.fetchAdress = null;
        this.fetchBody = null;
        this.pcode = null;
        this.token = null;
        this.countLoad = 0;

        this.onCodeChange = this.onCodeChange.bind(this);
        this.onNavSelect = this.onNavSelect.bind(this);
        this.onNavSearch = this.onNavSearch.bind(this);
        this.navSearch = this.navSearch.bind(this);
        this.onNavLink = this.onNavLink.bind(this);
        this.onSetComp = this.onSetComp.bind(this);
        this.onClickExit = this.onClickExit.bind(this);
        this.setAppState = this.setAppState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveClient = this.saveClient.bind(this);
        this.resetApp = this.resetApp.bind(this);
    }

    x_fetchData = async () => {

        var body = null;
        if(this.pcode && this.state.pCode.length === 7 && this.pcode === this.state.pCode) {
            body = "list=yes&code=" + this.state.pCode;
            this.pcode = null;
        }

        if(this.token === this.state.token && this.token !== null) {
            body = "token=" + this.state.token;
            this.token = null;
        }
        //console.log("Home _fetchData comp", this.state.comp);
        
        if(body !== null) {
            body += "&uuid=" + localStorage.getItem('UUID');
            //console.log("Home _fetchData body", body);

            const response = await fetch(localStorage.getItem('server') + '/', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body,
            });
            this.fetchBody = '';
            const responseJson = await response.json();
           //console.log('login', responseJson);
            if(responseJson.status && responseJson.uuid) {
                sessionStorage.setItem('myUser', JSON.stringify(responseJson.user));
                sessionStorage.setItem('myService', JSON.stringify(responseJson.serviceUser));
                sessionStorage.setItem('myCalendar', JSON.stringify(responseJson.calendar.calendar));
                sessionStorage.setItem('myProductArea', JSON.stringify(responseJson.product.area));
                sessionStorage.setItem('myProductInfo', JSON.stringify(responseJson.product.info));

                var calendarList = {};
                // eslint-disable-next-line array-callback-return
                responseJson.calendar.calendar.map((cal, i) => {
                    calendarList[cal.id] = cal;
                });
                sessionStorage.setItem('myCalList', JSON.stringify(calendarList));
                delete responseJson.mitarbeiter;
                this.setState({myService: responseJson.serviceUser});
            } else if(!responseJson.uuid) {
                this.setState({uuidFail: true, pCode: ''});
            } else if(this.state.pCode.length === 7) {
                this.setState({isFail: true, pCode: ''});
            } else {
                //this.onClickExit();
            }

        }
    };

    _fetchData = async () => {
        this.countLoad += 1;

        const setDataAdress = localStorage.getItem('server') + '/';

        var setDataBody = null;
        if(this.pcode && this.state.pCode.length === 7 && this.pcode === this.state.pCode) {
            setDataBody = "list=yes&code=" + this.state.pCode;
            this.pcode = null;
        }

        if(this.token === this.state.token && this.token !== null) {
            setDataBody = "token=" + this.state.token;
            this.token = null;
        }
        //console.log(this.countLoad + "Home _fetchData comp", this.state.comp);
        
        if(setDataBody !== null) {
            setDataBody += "&uuid=" + localStorage.getItem('UUID');
            //console.log("Home _fetchData body", setDataBody);


            ApiCall(setDataAdress, setDataBody)
            .then((response) => {
                // Verarbeiten Sie den Response hier, wenn gewünscht
                this.errorResponse = false;
    
               //console.log('call', response);
                if(response.status && response.uuid) {
                    sessionStorage.setItem('myUser', JSON.stringify(response.user));
                    sessionStorage.setItem('myService', JSON.stringify(response.serviceUser));
                    sessionStorage.setItem('myCalendar', JSON.stringify(response.calendar.calendar));
                    sessionStorage.setItem('myProductArea', JSON.stringify(response.product.area));
                    sessionStorage.setItem('myProductInfo', JSON.stringify(response.product.info));                    
                    var calendarList = {};
                    // eslint-disable-next-line array-callback-return
                    response.calendar.calendar.map((cal, i) => {
                        calendarList[cal.id] = cal;
                    });
                    sessionStorage.setItem('myCalendarList', JSON.stringify(calendarList));
                    delete response.mitarbeiter;
                    this.setState({
                        myService: response.serviceUser,
                        isLoading: false,
                        errorResponse: this.errorResponse 
                    });
                } else if(!response.uuid) {
                    this.setState({
                        uuidFail: true, 
                        pCode: '',
                        isLoading: false,
                        errorResponse: this.errorResponse 
                    });
                } else if(this.state.pCode.length === 7) {
                    this.setState({
                        isFail: true, 
                        pCode: '',
                        isLoading: false,
                        errorResponse: this.errorResponse 
                    });
                } else {
                    this.onClickExit();
                }
            })
            .catch((error) => {
                // Behandeln Sie Fehler, die von der Funktion ausgelöst werden
                this.errorResponse = true;
    
                this.setState({ 
                    time: Date.now(), 
                    isLoading: false,
                    errorResponse: this.errorResponse 
    
                });
                //console.log('call', error);
            });

        }
    };

    loadData() {

    }

    saveClient(event) {
        this.pcode = event.target.value;

        if(this.pcode.length === 7 && this.state.clientId.length > 4 && this.state.clientType !== '') {
            this.fetchAdress = localStorage.getItem('server') + '/v1.0/';
            this.fetchBody = "list=yes&code=" + this.state.pCode;
            this.fetchBody += "&clientId=" + this.state.clientId;
            this.fetchBody += "&clientType=" + this.state.clientType;

            //console.log(this.fetchBody);
        } else {
            this.setState({pCode: event.target.value});
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    onClickExit() {
        sessionStorage.clear();
        window.location.href = '/';
    }

    onCodeChange(event) {
        this.pcode = event.target.value;
        this.setState({
            pCode: event.target.value,
        });
    }

    onNavSelect(event) {
        //console.log("onNavSelect", event.target.value);
        this.setState({
            searchString: '',
            comp: event.target.value,
        });
    }

    onNavLink(event) {
        const val = event.target.attributes.value;
        //console.log("onNavLink", event.target.attributes, val.value);
        this.setState({
            searchString: '',
            comp: val.value,
        });
    }

    onSetComp(dataset) {
        //console.log('onSetComp', dataset);
        this.setState({
            searchString: '',
            comp: dataset.comptype,
            fetchDate: dataset.compdate,
            calendarId: dataset.compcalendarid
        });
    }

    setAppState() {
        //console.log(this.state.devApp);
        if(this.state.devApp) {
            localStorage.setItem('server', 'https://d.24-expert.de/service/v1.0');
            localStorage.setItem('imageserver', 'https://serviceimage.24-expert.de/');
            localStorage.setItem('dev', false);
            this.setState({
                devApp: false
            });
        } else {
            localStorage.setItem('server', 'https://d.entwicklung.24-expert.de/service/v1.0');
            localStorage.setItem('imageserver', 'https://serviceimage.entwicklung.24-expert.de/');
            localStorage.setItem('dev', true);
            this.setState({
                devApp: true
            });
        }
    }

    resetApp() {
        //localStorage.setItem('UUID', this.state.uuid);
        localStorage.removeItem('client');
        sessionStorage.clear();
        window.location.reload(false);
    }

    onNavSearch(event) {
        //console.log("Home onNavSearch event:", event.target.value);
        this.navSearch(event.target.value);
    }

    navSearch(searchString) {
        //console.log("Home navSearch searchString:", searchString);
        this.setState({
            searchString: searchString
        });
    }

    componentDidUpdate() {
        this._fetchData();
    }

    componentDidMount() {
        var comp = 'TerminList';
        if(this.props.comp) {
            comp = this.props.comp;
        }
        var dev = true;
        if(localStorage.getItem('dev') === 'false') {
            dev = false;
        }
       
        if(sessionStorage.getItem('myService') && localStorage.getItem('client') && localStorage.getItem('clienttype')) {

            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.setState({
                searchString: '',
                myService: responseJson,
                token: responseJson.Ergebnis,
                devApp: dev,
                comp: comp,

            });
        } else {
            this.setState({
                devApp: dev
            });
        }
    }

    render() {

       //console.log("Home myService", this.state.myService);
        const zugriff = ['6','8','11','19'];


        let vis;
        if(!this.state.uuidFail) {
            vis = {visibility: 'collapse'};
        } else {
            vis = {visibility: 'visible'};
        }

        //this.state.myService.MitarbeiterAbteilung

        //console.log(process.env.REACT_APP_API_URL);

        if((!localStorage.getItem('UUID') || !localStorage.getItem('client') || !localStorage.getItem('clienttype'))) {

            return (
                <ClientSetup />
            );
            // && zugriff.includes(this.state.myService.MitarbeiterAbteilung)
        } else if(this.state.myService && this.state.myService['OK'] === "OK") {
            var component = '';

            if(this.state.searchString !== null && this.state.searchString !== '') {
                return (
                    <>
                        <ExNavbar onNavSelect={this.onNavSelect} onNavLink={this.onNavLink} onNavSearch={this.onNavSearch} onLogout={this.onClickExit} myService={this.state.myService} searchString={this.state.searchString}  style={{overflow: 'hidden'}}/>
                        <Container fluid style={{paddingTop: "55px"}}>
                            <TerminWorkSearch searchString={this.state.searchString} />
                        </Container>
                    </>
                );
            } else if(this.state.comp !== '') {

                if(this.state.comp === 'TerminSearch') {
    
                    component = <TerminSearch />;
                } else if(this.state.comp === 'DatesList') {
    
                    component = <DatesList />;
                } else if(this.state.comp === 'TerminWorkorder') {
    
                    component = <TerminWorkorder />;
                } else if(this.state.comp === 'ServiceConfig') {
    
                    component = <ServiceConfig />;                    
                } else if(this.state.comp === 'CalendarDay') {
                    //console.log("Home myService comp day", this.state.comp);

                    component = <CalendarDay myservice={this.state.myService} onSetComp={this.onSetComp} calendarid={this.state.calendarId} fetchDate={this.state.fetchDate}/>

                } else if(this.state.comp === 'CalendarWeek') {
                    //console.log("Home myService comp week", this.state.comp);

                    component = <CalendarWeek myservice={this.state.myService} onSetComp={this.onSetComp} calendarid={this.state.calendarId} fetchDate={this.state.fetchDate} />

                } else if(this.state.comp === 'CalendarMonth') {
                    //console.log("Home myService comp week", this.state.comp);

                    component = <CalendarMonth myservice={this.state.myService} onSetComp={this.onSetComp} calendarid={this.state.calendarId} fetchDate={this.state.fetchDate} />

                } else if(localStorage.getItem('clienttype') === 'desktop') {
                    if(zugriff.includes(this.state.myService.MitarbeiterAbteilung)) {
                        component = <Container myservice={this.state.myService} fluid>
                                        <TerminList />
                                        <br />
                                        <SparepartList />
                                    </Container>;
                    } else {
                        component = <Container myservice={this.state.myService} fluid>
                                        <TerminSearch week={2}/>
                                        <br />
                                        <TerminList />
                                    </Container>;
                    }

                } else if(localStorage.getItem('clienttype') === 'mobile') {
                    if(zugriff.includes(this.state.myService.MitarbeiterAbteilung)) {
                        component = <Container myservice={this.state.myService} fluid>
                                        <TerminWorkList />
                                    </Container>;

                    } else {
                        component = <Container myservice={this.state.myService} fluid>
                                        <TerminSearch  week={1}/>
                                    </Container>;
                    }
                }
    
            }
            return (
                <>
                    <ExNavbar setAppState={this.setAppState} devApp={this.state.devApp} onNavSelect={this.onNavSelect} onNavLink={this.onNavLink} onNavSearch={this.onNavSearch} onLogout={this.onClickExit} fixed="top" container="fluid" color="secondary" light="true" myService={this.state.myService} searchString={this.state.searchString}  style={{overflow: 'hidden'}}/>
                    <Container fluid style={{paddingTop: "55px"}}>
                        {component}
                    </Container>
                </>
            );

        } else {

            
            return (
                <Container>
                    <Card
                        className="position-absolute top-50 start-50 translate-middle"
                        style={{
                            width: '18rem'
                        }}
                    >
                        <Card.Header>ServicePlaner</Card.Header>
                        <Card.Body>
                            <Form>
                                <FloatingLabel controlId="floatingPassword" label="Personal Code">
                                    <Form.Control
                                        onChange={this.onCodeChange}
                                        value={this.state.pCode}
                                        type="password"
                                        placeholder="Password"
                                        isInvalid={this.state.isFail}
                                        autoComplete="off"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Dieser Personalcode ist nicht gültig!
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={localStorage.getItem('client')}
                                        isInvalid={this.state.uuidFail}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Dieses Gerät ist nicht registriert!
                                    </Form.Control.Feedback>
                                </InputGroup>
                                <Form.Check
                                    type="switch"
                                    id="devSwitch"
                                    label="Develop"
                                    checked={this.state.devApp}
                                    onChange={this.setAppState}
                                    
                                />
                                <Button color="danger" style={vis} onClick={this.resetApp}>
                                    Zurücksetzen
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            );
        }


    }
}