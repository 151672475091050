import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalHeader from "react-bootstrap/ModalHeader";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import ExSignature from './Signature'



export default class TerminWorkorder extends Component {

    state = {
        isloading: false,
        element: null,
        calendardate: null,
        sign: null,
        newArtikel: '',
        newText:'',
        formData: {
            artikel: [],
        },
        setDataAdress: null,
        setDataBody: null,
    };

    constructor(props) {
        super(props);



        this.sign = '';
        this.setDataBody = [];
        this.setDataAdress = '';

        this.onSign = this.onSign.bind(this);
        this.onSignOk = this.onSignOk.bind(this);
        this.addArtikel = this.addArtikel.bind(this);
        this.addText = this.addText.bind(this);
        this.submitText = this.submitText.bind(this);
    }

    componentDidMount() {
        //console.log('componentDidMount');

        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.type = this.props.type;
            this.setState({
                isloading: true,
                myService: responseJson,
                element: this.props.element,
                calendardate: this.props.calendardate,
                token: responseJson.Ergebnis
            });
        }
    }

    componentDidUpdate() {
        if(this.state.setDataAdress !== null) {
            this._setData();
        }
    }

    _setData = async () => {
        //console.log(this.setDataAdress, this.setDataBody)

        const response = await fetch(this.setDataAdress, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: this.setDataBody,
        });
        //console.log(response.text());
        // eslint-disable-next-line no-unused-vars
        const responseJson = await response.json();

        //console.log("_setData",responseJson);
        if(this.state.setDataAdress === localStorage.getItem('server') + '/dates/sign') {
            window.location.reload(false);
        }
        this.setState({
            setDataAdress: null,
            setDataBody: null,
        });
    }

    _fetchArtikel = async (body) => {
        console.log(body);
     
             const response = await fetch(localStorage.getItem('server') + '/artikel/get', {
                 headers: {
                     'Content-Type': 'application/x-www-form-urlencoded',
                 },            
                 method: 'POST',
                 body: body,
             });
             // eslint-disable-next-line no-unused-vars
             //console.log(response.text());
             const responseJson = await response.json();
             let artikel = this.state.formData.artikel;
             let workOrder = this.state.element.workOrder;
             const artnr = responseJson.parameters.params.id;
             const tln = workOrder.length + 1;
 
            console.log("return", artnr, responseJson.status.data, responseJson);
 
             if(responseJson.status !== false && responseJson.data !== false) {
 
                 //Artikel  bezeichnung und menge zu this.state.artikel hinzufügen
 
                 artikel.push({artnr: artnr, arttyp: 'bekannt', name: responseJson.data.bezeichnung, menge: 1, deliver: false, setup: false, connect: false, dispose: false, optData: {}});
                 workOrder.push({typ: 'ARTIKEL', artnr: artnr, bezeichnung: responseJson.data.bezeichnung, menge: 1, einheit: 'STK', ln: tln, pid: this.state.myService.Mitarbeiter});

                 this.setDataBody = "token=" + this.state.token;
                 this.setDataBody += "&params[order_id]=" + this.state.element.order_id;
                 this.setDataBody += "&params[order_dates_id]=" + this.state.element.id;
                 this.setDataBody += "&params[ln]=" + tln;
                 this.setDataBody += "&params[typ]=ARTIKEL";
                 this.setDataBody += "&params[artnr]=" + artnr;
                 this.setDataBody += "&params[bezeichnung]=" + responseJson.data.bezeichnung;
                 this.setDataBody += "&params[menge]=1";
                 this.setDataBody += "&params[einheit]=STK";
                 this.setDataBody += "&params[netto]=0";
                 this.setDataBody += "&params[mwst]=19";
                 this.setDataBody += "&params[pid]=" + this.state.myService.Mitarbeiter;

                 this.setDataAdress = localStorage.getItem('server') + '/dates/addPosition';

                 //console.log("return", responseJson);
                 //return responseJson.data;
                 //this.props.toggle();
                 //window.location.reload(false);
     
             } else if(responseJson.status) {
                 artikel.push({artnr: artnr, arttyp: 'unbekannt', name: '', menge: 1, deliver: false, setup: false, connect: false, dispose: false, optData: {}});

             }        
             this.setState({
                formData: {
                     ...this.state.formData,
                     artikel: artikel
                },
                element: {
                    ...this.state.element,
                    workOrder: workOrder
                },
                newArtikel: '',
                setDataAdress: this.setDataAdress,
                setDataBody: this.setData
             });                
    }

    addArtikel(event) {
        if(event.target.value.length === 11) {
            //console.log(event.target.value);
            var body = '';
            body += `params[id]=` + event.target.value
            body += "&token=999988887777666655554444333322221111";
            //console.log(body);
            this._fetchArtikel(body);
        } else {
            this.setState({
                newArtikel: event.target.value
            });
        }
    }

    addText(event) {    
        this.setState({
            newText: event.target.value
        });
    }

    submitText() {

        let workOrder = this.state.element.workOrder;
        const tln = workOrder.length + 1;

        workOrder.push({typ: 'TEXT', artnr: 'TEXT', bezeichnung: this.state.newText, menge: 0, einheit: '', ln: tln, pid: this.state.myService.Mitarbeiter});


        this.setDataBody = "token=" + this.state.token;
        this.setDataBody += "&params[order_id]=" + this.state.element.order_id;
        this.setDataBody += "&params[order_dates_id]=" + this.state.element.id;
        this.setDataBody += "&params[ln]=" + tln;
        this.setDataBody += "&params[typ]=TEXT";
        this.setDataBody += "&params[artnr]=null";
        this.setDataBody += "&params[bezeichnung]=" + this.state.newText;
        this.setDataBody += "&params[menge]=0";
        this.setDataBody += "&params[einheit]=";
        this.setDataBody += "&params[netto]=";
        this.setDataBody += "&params[mwst]=";
        this.setDataBody += "&params[pid]=" + this.state.myService.Mitarbeiter;

        this.setDataAdress = localStorage.getItem('server') + '/dates/addPosition';

        this.setState({
            element: {
                ...this.state.element,
                workOrder: workOrder
            },
            newArtikel: '',
            setDataAdress: this.setDataAdress,
            setDataBody: this.setData
        });

    }


    onSign(sign) {
        //console.log(sign);
        //this.setState({
        this.sign =  sign;
        //});

    }

    onSignOk() {
        this.setDataBody = "token=" + this.state.token;
        this.setDataBody += "&params[id]=" + this.state.element.id;
        this.setDataBody += "&params[sign]=" + this.sign;
        

        //console.log(this.setDataBody);
        this.setDataAdress = localStorage.getItem('server') + '/dates/sign';

        this.setState({
            setDataAdress: this.setDataAdress,
            setDataBody: this.setDataBody
        });

    }

    render() {
        //const element = this.props.element;

        if(this.state.isloading) {
            //console.log('AUFTRAG', this.state.element)
            var signField = <ExSignature onSign={this.onSign} />;
            var signButton = <Button
            color="primary"
            className="SignButton"
            onClick={this.onSignOk}
            >OK</Button>;
            if(this.state.element.sign) {
                signField = <img src={localStorage.getItem('imageserver') + '/' + this.state.token + '/' + this.state.element.sign} className="signature" width="100" height="50" alt="base64 test"/>;
                //signField = <h5>Unterschrieben</h5>;
                signButton = '';
            }

            console.log(this.state.element);
            console.log(this.state.formData.artikel);
            return (
                <Form>
                    <ModalHeader className='bg-warning' closeButton>
                        <div className='h4'>SERVICE-AUFTRAG / QUITTUNG</div>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className="fs-5" sm="7">
                                {this.state.element.name}<br/>
                                {this.state.element.street}<br/><br/>
                                {this.state.element.zip} {this.state.element.city}<br/>
                            </Col>
                            <Col sm="2" className='text-end fs-5'>
                                Datum<br/><br/>
                                KundenNr.<br/>
                                Telefon<br/>
                                Handy<br/>
                                Email<br/>
                            </Col>
                            <Col sm="3" className='fs-5'>
                                : {this.state.element.order_date}<br/><br/>
                                : {this.state.element.debitor_id}<br/>
                                : {this.state.element.telefon}<br/>
                                : {this.state.element.mobil}<br/>
                                : {this.state.element.mailaddress}<br/>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm="8" className='fs-5'>
                                <strong className='fs-5'>SERVICE-AUFTRAG / QUITTUNG</strong><br/>
                                <strong className='fs-5'>Nr.: {this.state.element.order_sid}</strong><br/>
                                (bei Rückfragen bitte angeben!)<br/><br/>
                                <strong className='fs-5'>Auftrag / Fehlerbeschreibung</strong><br/>
                                {this.state.element.order}<br/>
                            </Col>
                            <Col sm="1" className='fs-5'>
                                <br/>
                                <strong className='fs-5'>Datum</strong><br/>
                                <br/>
                                <strong className='fs-5'>Ankunft</strong><br/>
                                <strong className='fs-5'>Abfahrt</strong><br/>
                            </Col>
                            <Col className="text-right fs-5" sm="3">
                                <br/>
                                <strong className='fs-5'>: {this.state.element.order_date}</strong><br/>
                                <br />
                                <strong className='fs-5'>: {this.state.element.stempstart} Uhr</strong><br/>
                                <strong className='fs-5'>: {this.state.element.stempend} Uhr</strong><br/>
                                <br/>
                            </Col>
                        </Row>
                        <hr />
                        <Table striped>
                            <thead>
                                <tr>
                                    <th className='fs-5' style={{width: '50px'}}>#</th>
                                    <th colSpan={2} className='fs-5'>Arbeit / Marterial</th>
                                    <th colSpan={2} style={{width: '80px'}} className="text-end fs-5">Menge</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.element.workOrder.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope="row" className='fs-5'>{index + 1}</th>
                                                <td className='fs-5' style={{width: '120px'}}>{ item.typ === 'ARTIKEL' ? item.artnr : item.typ}</td>
                                                <td className='fs-5'>{item.bezeichnung}</td>
                                                <td className="text-end fs-5">
                                                    { this.state.element.sign === null && item.typ === 'ARTIKEL' ? <i className="bi bi-dash-square pe-3"></i> : ''}
                                                    { item.typ === 'TEXT' ? '' : item.menge}
                                                    { this.state.element.sign === null && item.typ === 'ARTIKEL' ? <i className="bi bi-plus-square ps-3"></i> : ''}
                                                </td>
                                                <td style={{width: '36px'}} className="text-start fs-5"> {item.einheit}</td>
                                            </tr>
                                        )
                                    })
                                }
                         
                            </tbody>
                            <tfoot>
                                {
                                    this.state.element.sign === null 
                                    ? <tr>
                                        <th className="p-0" colSpan={2}>
                                            <Form.Control 
                                                size="sm"
                                                type="text" 
                                                name="artikelnr"
                                                autoComplete='off'
                                                placeholder="Artikel Nr." 
                                                onChange={this.addArtikel}
                                                value={this.state.newArtikel}
                                                //isInvalid={!this.state.formData.check.artikel && this.state.formData.check.artikel !== null}
                                                className="form-control-lg p-0"
                                                disabled={this.state.newArtikel === 11}
                                            />
                                        </th>                                 
                                    </tr>    
                                    : ''
                                }
                                                        
                            </tfoot>
                        </Table>
                    </ModalBody>
                    <ModalFooter className="pb-5 fixed-bottom">
                    <Table striped>
                            <thead>
                            {
                                    this.state.element.sign === null 
                                    ? <tr>
                                        <th  colSpan={4}>
                                            <Form.Control 
                                                size="sm"
                                                type="text" 
                                                name="leistungstext"
                                                autoComplete='off'
                                                placeholder="Leistungstext" 
                                                onChange={this.addText}
                                                value={this.state.newText}
                                                //isInvalid={!this.state.formData.check.artikel && this.state.formData.check.artikel !== null}
                                                className="form-control-lg p-0"
                                            />
                                        </th>
                                        <th>
                                            <Button
                                                size='md'
                                                color="primary"
                                                onClick={this.submitText}
                                            >hinzufügen</Button>
                                        </th>
                                    </tr>      
                                    : ''
                                }

                            </thead>
                            <tbody>
                                <tr>
                                    <th className='col-md-1' scope="row"></th>
                                    <td></td>
                                    <td className="col-md-2 text-end"></td>
                                    <td className="col-md-2 text-end">Summe Netto</td>
                                    <td className="col-md-2 text-end"></td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td className="text-end"></td>
                                    <td className="text-end">MwSt.</td>
                                    <td className="text-end"></td>
                                </tr>                        
                                <tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td className="text-end"></td>
                                    <td className="text-end">Gesamt Brutto</td>
                                    <td className="text-end"></td>
                                </tr>                            
                            </tbody>
                        </Table>        
                        {signField}
                        {signButton}
                    </ModalFooter>
                </Form>
            )
        }


    }
}
