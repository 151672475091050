import React from 'react'; 
//import {  Pagination,  Pagination.Item,  Pagination.Link} from 'reactstrap';
import Pagination from 'react-bootstrap/Pagination';

export default function ExPagination(props) {
    
  const first = '1';
  const previous = props.pages.previoussite;
  const next = props.pages.nextsite;
  const last = props.pages.pagescount;
  let prevEllipsis = null;
  let nextEllipsis = null;
  let firstPagination = null;
  let lastPagination = null;
  

  //console.log('ExPagination', props);


  if(props.pages) {


   //console.log(props.pages);


    if(props.pages.rows >= 1) {

      if(props.pages.activesite > 6) {
        prevEllipsis = <Pagination.Ellipsis />;
      }

      if(props.pages.activesite < (props.pages.pagescount - 5)) {
        nextEllipsis = <Pagination.Ellipsis />;
      }

      if(props.pages.pagescount > 1) {
        firstPagination = <Pagination.Item data-page={first} onClick={props.onPaging} active={first === props.pages.activesite}>{first}</Pagination.Item>;
      }

      if(first !== last) {
        lastPagination = <Pagination.Item data-page={last} onClick={props.onPaging} active={last === props.pages.activesite}>{last}</Pagination.Item>;
      }

      return (
        <Pagination>
          <Pagination.Item data-page={first} onClick={props.onPaging} disabled={1 === props.pages.activesite}>«</Pagination.Item>
          <Pagination.Item data-page={previous} onClick={props.onPaging} disabled={!previous}>‹</Pagination.Item>
          {firstPagination}
          {prevEllipsis}

          {
            props.pages.pages.map((element, index) => {

              if(element > 1 && element < props.pages.pagescount) {
                return (<Pagination.Item key={index} data-page={element} onClick={props.onPaging} active={element === props.pages.activesite}>{element}</Pagination.Item>);
              } else {
                return null;
              }
            })
          }

          {nextEllipsis}

          {lastPagination}
          <Pagination.Item data-page={next} onClick={props.onPaging} disabled={!next}>›</Pagination.Item>
          <Pagination.Item data-page={last} onClick={props.onPaging} disabled={last === props.pages.activesite}>»</Pagination.Item>
        </Pagination>        
      );
    } else {
      return (<></>);
    }

  }



}

/*

      <Pagination
      aria-label="Page navigation example"
      style={{alignItems: "center", margin: "0 auto", justifyContent: "center"}}
      >
          {first}
          {previous}
          {
            // eslint-disable-next-line array-callback-return
            props.pages.pages.map((element, index) => {
              if(element === props.pages.activesite) {
                return(
                  <Pagination.Item active>
                    <Pagination.Link 
                    key={index}
                    data-page={element}
                    onClick={props.onPaging}
                    >
                    Seite {element}
                    </Pagination.Link>
                  </Pagination.Item>

                )
              } else if(element > 0 && element <= props.pages.pagescount) {
                return(
                  <Pagination.Item>

                  <Pagination.Link 
                  key={index}
                  data-page={element}
                  onClick={props.onPaging}
                  >
                  {element}
                  </Pagination.Link>
                  </Pagination.Item>

                )
              }
            })
          }
          {next}
          <Pagination.Item>
              <Pagination.Link
              last
              data-page={props.pages.pagescount}
              onClick={props.onPaging}
              />
          </Pagination.Item>
      </Pagination>

*/